@font-face {
  font-family: SctoGroteskA;
  src: url('./fonts/scto_grotesk_a/Scto\ Grotesk\ A\ Regular.otf') format('opentype');
}

@font-face {
  font-family: SctoGroteskA;
  font-style: italic;
  src: url('./fonts/scto_grotesk_a/Scto\ Grotesk\ A\ Medium\ Italic.otf') format('opentype');
}

@font-face {
  font-family: SctoGroteskA;
  font-weight: bold;
  src: url('./fonts/scto_grotesk_a/Scto\ Grotesk\ A\ Bold.otf') format('opentype');
}

@font-face {
  font-family: SctoGroteskA;
  font-style: italic;
  font-weight: bold;
  src: url('./fonts/scto_grotesk_a/Scto\ Grotesk\ A\ Black\ Italic.otf') format('opentype');
}

@font-face {
  font-family: SctoGroteskA;
  font-weight: lighter;
  src: url('./fonts/scto_grotesk_a/Scto\ Grotesk\ A\ Light.otf') format('opentype');
}

@font-face {
  font-family: SctoGroteskA;
  font-weight: lighter;
  font-style: italic;
  src: url('./fonts/scto_grotesk_a/Scto\ Grotesk\ A\ Light\ Italic.otf') format('opentype');
}

body {
  font-family: SctoGroteskA, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto';
  background: #f5f5f5;
}

textarea,
input,
select {
  font-family: SctoGroteskA, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto';
}

.Popover {
  z-index: 99;
}

.Popover-body {
  background-color: #fff;
  box-sizing: border-box;
  font-size: 15px;
  border-radius: 4px;
  box-shadow: 0 0 10px #00000021;
}

.Popover-tip {
  fill: #fff;
}

.tip-right .Popover-tip {
  transform: translateX(300px) !important;
}

@media screen and (max-width: 767px) {
  .tip-right .Popover-tip {
    display: none;
  }
}
